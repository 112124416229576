import React from 'react';

export const Hex = () => (
  <svg
    width="562"
    height="585"
    viewBox="0 0 562 585"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M205.86 12.829C219.681 1.20939 238.65 -2.14725 255.62 4.02345L485.767 87.7116C502.737 93.8823 515.13 108.643 518.278 126.433L560.974 367.708C564.123 385.498 557.548 403.616 543.726 415.235L356.275 572.822C342.453 584.442 323.485 587.798 306.515 581.628L76.3674 497.94C59.3976 491.769 47.0044 477.008 43.8562 459.218L1.16 217.943C-1.98818 200.153 4.58693 182.035 18.4086 170.416L205.86 12.829Z"
      fill="#FAFCFE"
    />
  </svg>
);
